import { EnvironmentConfig } from "../app/shared/models/config/environment-config.model";

const api_key = 'N29nKzc5R1hoWTE4eHJPcFYvNktHRGNrMTlnYnJYTksrS2p3NjNmSldVWmloZDhYazhCZnUwZ0hKU0ZxVHo3dmJXeEk0UGlNV08rWTUreGF0UFczL01WMlV5R2NhSmdUdmIvWU1wendkUW89';
const key_sig = 'B72144D5-1BD5-4938-A02F-4CF0FF38C753';

export const environment: EnvironmentConfig = {
  production: false,
  contacts: [
    { key: 'b2b', phone: '1-877-876-0827' },
    { key: 'b2c', phone: '1-800-876-6827' }
  ],
  clients: [
    {
      name: 'events',
      url: 'https://sb-events.nna-platform.com',
      prefix: 'api/v1',
      authType: 'key-sig',
      authKey: api_key,
      authSecret: key_sig,
    },
    {
      name: 'orders',
      url: 'https://sb-orders.nna-platform.com',
      prefix: 'api/v1',
      authType: 'key-sig',
      authKey: api_key,
      authSecret: key_sig,
    }
  ]
}
